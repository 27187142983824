// import React, { useState, useEffect } from 'react';
import React, { useEffect } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Card from "react-bootstrap/Card";

import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";

const data2 = {
  labels: ["PKW", "LKW", "Motorrad", "Fahrrad", "Fußgänger"],
  datasets: [
    {
      data: [121, 41, 19, 32, 68],
      borderWidth: 0,
      borderColor: ["#598292", "#7CCDEC", "#FFFFFF", "#E0896F", "#885D50"],
      backgroundColor: ["#598292", "#7CCDEC", "#FFFFFF", "#E0896F", "#885D50"],
    },
  ],
};

const options2 = {
  legend: {
    position: "right",
    labels: {
      usePointStyle: true,
      // boxWidth: 15,
      fontFamily: "Roboto-Regular",
      fontColor: "#FFFFFF",
    },
  },
};

const data = {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "PKW",
      data: [65, 59, 80, 81, 56, 55, 40],
      borderColor: "#598292",
      backgroundColor: "#598292",
      fill: false,
    },
    {
      label: "LKW",
      data: [55, 49, 88, 41, 6, 25, 20],
      borderColor: "#7CCDEC",
      backgroundColor: "#7CCDEC",
      fill: false,
    },
    {
      label: "Motorrad",
      data: [60, 50, 83, 8, 50, 50, 20],
      borderColor: "#FFFFFF",
      backgroundColor: "#FFFFFF",
      fill: false,
    },
    {
      label: "Fahrrad",
      data: [55, 69, 70, 71, 66, 65, 50],
      borderColor: "#E0896F",
      backgroundColor: "#E0896F",
      fill: false,
    },
    {
      label: "Fußgänger",
      data: [35, 39, 30, 41, 46, 25, 20],
      borderColor: "#885D50",
      backgroundColor: "#885D50",
      fill: false,
    },
  ],
};

const options = {
    // elements: {
    //         point: {
    //                 // radius: 10,
    //         },
    // },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: "#FFFFFF",
          fontFamily: "Roboto-Regular",
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          fontColor: "#FFFFFF",
          fontFamily: "Roboto-Regular",
        },
      },
    ],
  },
  legend: {
    align: "end",
    labels: {
      usePointStyle: true,
      // boxWidth: 10,
      fontColor: "#FFFFFF",
      fontFamily: "Roboto-Regular",
    },
  },
};

function SVGPoint({ fill }) {
    const r = 8
    const d = 2 * r
  return (
    <svg
      width={`${d}px`}
      height={`${d}px`}
      viewBox={`0 0 ${d} ${d}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="1" fill={fill}>
        <circle id="svg-point" cx={`${r}`} cy={`${r}`} r={`${r}`}></circle>
      </g>
    </svg>
  );
}

function App() {
  useEffect(() => {
    // console.log(document.getElementById("smart-traffic-cam-to-go"));
  });

  return (
    <Container fluid className="mb-3" style={{ border: "solid red 0px" }}>
      <Row>
        <Col lg={8} style={{ border: "solid red 0px" }}>
          <Card className="border-0 shadow mt-3 text-light bg-dark">
            <Card.Body>
              <Card.Title>SMART TRAFFIC CAM TO GO</Card.Title>
              <Container style={{ border: "solid red 0px"}}>
                <Line data={data} options={options} />
              </Container>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4}>
          <Card className="border-0 shadow mt-3 text-light bg-dark">
            <Card.Body>
              <Card.Title>ÜBERSICHT</Card.Title>
              <Container style={{ border: "solid red 0px" }}>
                <Doughnut data={data2} options={options2} />
              </Container>
            </Card.Body>
          </Card>

          <Card className="border-0 shadow mt-3 text-light bg-dark">
            <Card.Body>
              <Card.Title>AKTUELL</Card.Title>
              <Container style={{ border: "solid red 0px" }}>
                <Row>
                  <Col style={{ border: "solid red 0px" }} className="p-0">
                    <SVGPoint fill="#598292" /> PKW
                    <br />
                    <h1>121</h1>
                  </Col>
                  <Col style={{ border: "solid red 0px" }} className="p-0">
                    <SVGPoint fill="#7CCDEC" /> LKW
                    <br />
                    <h1>41</h1>
                  </Col>
                  <Col style={{ border: "solid red 0px" }} className="p-0">
                    <SVGPoint fill="#FFFFFF" /> Motorrad
                    <br />
                    <h1>19</h1>
                  </Col>
                </Row>
                <Row>
                  <Col className="p-0" style={{ border: "solid red 0px" }}>
                    <SVGPoint fill="#E0896F" /> Fahrrad
                    <br />
                    <h1>32</h1>
                  </Col>
                  <Col className="p-0" style={{ border: "solid red 0px" }}>
                    <SVGPoint fill="#885D50" /> Fußgänger
                    <br />
                    <h1>68</h1>
                  </Col>
                  <Col className="p-0"></Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
